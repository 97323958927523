import React, { useState } from "react";
import {
  DialogTitle,
  Button,
  CircularProgress,
  Drawer,
  IconButton,
  Divider,
  Grid,
  Checkbox,
} from "@mui/material";
import MuiPhoneNumber from "material-ui-phone-number";
import * as Yup from "yup";
import { FormProvider } from "./../../hooks/hook-form";
import "./_modals.scss";
import { Controller, useForm } from "react-hook-form";
import { useDispatch, useSelector } from "react-redux";
import { yupResolver } from "@hookform/resolvers/yup";
import Input from "./../Input";
import SelectComponent from "./../Select";
import ProfileImage from "./../ProfileImage";
import { createDepartment, createOperator } from "./../../slices/operaters";
import Accordion from "@mui/material/Accordion";
import AccordionSummary from "@mui/material/AccordionSummary";
import Typography from "@mui/material/Typography";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import {
  enqueueSnackbar as enqueueSnackbarAction,
  closeSnackbar as closeSnackbarAction,
} from "../../slices/notifier";
import { Close } from "./../../assets";
import { useEffect } from "react";
import { LazyLoadImage } from "react-lazy-load-image-component";
import { useTranslation } from "react-i18next";
import { AdapterDayjs } from "@mui/x-date-pickers/AdapterDayjs";
import { LocalizationProvider } from "@mui/x-date-pickers/LocalizationProvider";
import { DemoContainer } from "@mui/x-date-pickers/internals/demo";
import { MultiInputTimeRangeField } from "@mui/x-date-pickers-pro/MultiInputTimeRangeField";
import { useFormik } from "formik";
import { addBotConfig, updateBotConfig } from "../../slices/bot";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import { nanoid } from "nanoid";
import RestartAltIcon from "@mui/icons-material/RestartAlt";
import dayjs from "dayjs";
import Constants from "../../utilities/constants";
import { validateBotConfig } from "../../utilities/validateBotConfig";
const AddOperator = ({ id, open, handleClose, data, ...rest }) => {
  const { t, i18n } = useTranslation();
  const dispatch = useDispatch();
  const { roles } = useSelector((state) => state.roles);
  const [isHovering, setIsHovering] = useState(false);
  const { newAvatar } = useSelector((state) => state.user);
  const { statusCreateOperator, statusCreateDepartment, departments } =
    useSelector((state) => state.operators);
  const { botConfigs, botConfigId } = useSelector((state) => state.bot);

  const enqueueSnackbar = (...args) => dispatch(enqueueSnackbarAction(...args));
  const closeSnackbar = (...args) => dispatch(closeSnackbarAction(...args));
  const showSnackbar = (data) => {
    // NOTE:
    // if you want to be able to dispatch a `closeSnackbar` action later on,
    // you SHOULD pass your own `key` in the options. `key` can be any sequence
    // of number or characters, but it has to be unique for a given snackbar.
    enqueueSnackbar({
      message: t(data.message),
      options: {
        key: new Date().getTime() + Math.random(),
        variant: data.variant,
        action: (key) => (
          <Button style={{ color: "white" }} onClick={() => closeSnackbar(key)}>
            {t("dismiss me")}
          </Button>
        ),
      },
    });
  };

  const handleMouseOver = () => {
    setIsHovering(true);
  };
  const handleMouseOut = () => {
    setIsHovering(false);
  };
  const LoginSchema = Yup.object().shape({
    email: Yup.string()
      .email(t("Email must be a valid email address"))
      .required(t("Email is required")),
    firstname: Yup.string().required(t("First Name is required")).trim(),
    lastname: Yup.string().required(t("Last Name is required")).trim(),
    password: Yup.string()
      .required(t("Password is required"))
      .trim()
      .matches(
        /^(?=.*[a-z])(?=.*[A-Z])(?=.*\d)(?=.*[#$@!%&*?])[A-Za-z\d#$@!%&*?]{8,30}$/,
        t(
          "Must Contain 8 Characters, One Uppercase, One Lowercase, One Number and One Special Case Character"
        )
      ),
    role: Yup.string().required(t("role is required")).trim(),
    phone: Yup.string().required(t("Phone number is required")).trim(),
  });
  const DepartmentSchema = Yup.object().shape({
    name: Yup.string().required(t("Name is required")).trim(),
    description: Yup.string().required(t("Description is required")).trim(),
  });
  const defaultValues =
    data?.id !== "create-department"
      ? {
          email: "",
          firstname: "",
          lastname: "",
          password: "",
          phone: "",
          department: "",
        }
      : {
          name: "",
          description: "",
        };
  const { user } = useSelector((state) => state.user);

  useEffect(() => {
    register("phone", { required: true });
  }, []);

  useEffect(() => {
    botConfigs?.length > 0 &&
      formik.setValues((state) => {
        return { ...state, days: botConfigs };
      });
  }, [botConfigs]);

  const methods = useForm({
    resolver: yupResolver(
      data?.id === "create-department" ? DepartmentSchema : LoginSchema
    ),
    defaultValues,
  });
  const {
    handleSubmit,
    formState: { isSubmitting },
    register,
  } = methods;
  const formik = useFormik({
    initialValues: {
      days: [],
    },

    onSubmit: (values) => {
      !validateBotConfig(values)
        ? showSnackbar({
            variant: "error",
            message: t(
              "Please review the intervals to ensure all are defined within valid ranges"
            ),
          })
        : (() => {
            botConfigId
              ? dispatch(
                  updateBotConfig({
                    ...values,
                    websiteID: data?.websiteID,
                    botConfigId,
                  })
                ).then((res) => {
                  !res?.error
                    ? (() => {
                        handleClose(id);
                        formik.setValues((state) => {
                          return { ...state, days: res.payload.data.days };
                        });
                      })()
                    : (() => {
                        showSnackbar({
                          variant: "error",
                          message: t(res?.error?.message),
                        });
                      })();
                })
              : dispatch(
                  addBotConfig({
                    ...values,
                    websiteID: data?.websiteID,
                  })
                ).then((res) => {
                  !res?.error
                    ? (() => {
                        handleClose(id);
                        formik.setValues((state) => {
                          return { ...state, days: res.payload.data.days };
                        });
                      })()
                    : (() => {
                        showSnackbar({
                          variant: "error",
                          message: t(res?.error?.message),
                        });
                      })();
                });
          })();
    },
  });

  const onSubmit = async (values) => {
    try {
      data?.id === "create-department"
        ? dispatch(
            createDepartment({
              websiteID: user.websiteID,
              data: {
                name: values.name,
                description: values.description,
              },
            })
          ).then((res) => {
            res?.error
              ? showSnackbar({
                  variant: "error",
                  message: t(res?.error?.message),
                })
              : (() => {
                  methods.reset();
                  showSnackbar({
                    variant: "success",
                    message: t("Department Created Successfully"),
                  });
                  handleClose(id);
                })();
          })
        : dispatch(
            createOperator({
              websiteID: user.websiteID,
              data: {
                firstName: values.firstname,
                lastName: values.lastname,
                email: values.email,
                password: values.password,
                department: values.department,
                phone: values.phone,
                role: values.role,
                file: newAvatar ? newAvatar : null,
              },
            })
          ).then((res) => {
            res?.error
              ? showSnackbar({
                  variant: "error",
                  message: t(res?.error?.message),
                })
              : (() => {
                  methods.reset();
                  showSnackbar({
                    variant: "success",
                    message: t(res?.payload?.message),
                  });
                  handleClose(id);
                })();
          });
    } catch (err) {
      console.error(err.message);
    }
  };
  const handleAddInterval = (day) => {
    const days = [...formik.values.days];
    const newDays = days.map((el) =>
      el.day.number === day?.value
        ? {
            ...el,
            day: {
              ...el.day,
              workInterval: [
                ...el.day.workInterval,
                { startDate: null, endDate: null, _id: nanoid() },
              ],
            },
          }
        : el
    );
    formik.setValues((state) => {
      return { ...state, days: newDays };
    });
  };
  const handleDeleteInterval = (day, id) => {
    const days = [...formik.values.days];
    const newDays = days.map((el) =>
      el.day.number === day?.value
        ? {
            ...el,
            day: {
              ...el.day,
              workInterval: el.day.workInterval.filter(
                (interval) => interval?._id !== id
              ),
            },
          }
        : el
    );
    formik.setValues((state) => {
      return { ...state, days: newDays };
    });
  };

  const handleChangeInterval = (e, id, day) => {
    const days = [...formik.values.days];
    const newDays = days.map((el) =>
      el.day.number === day?.value
        ? {
            ...el,
            day: {
              ...el.day,
              workInterval: el.day.workInterval.map((interval) =>
                interval._id === id
                  ? { ...interval, startDate: e[0], endDate: e[1] }
                  : interval
              ),
            },
          }
        : el
    );
    formik.setValues((state) => {
      return { ...state, days: newDays };
    });
  };

  return (
    <Drawer
      anchor={localStorage.getItem("i18nextLng") === "ar" ? "left" : "right"}
      open={open}
      onClose={() => handleClose(id)}
      PaperProps={{
        sx: {
          width: "35%",
          height: "100%",
        },
      }}
    >
      <DialogTitle className="drawer-title">
        {data?.id === "create-department"
          ? t("Add A New Department")
          : data?.id === "create-bot-config"
          ? t("Break your bot with lots of pauses.")
          : t("Add A New User")}
        <div style={{ marginTop: "-8px" }}>
          {data?.id === "create-bot-config" && (
            <IconButton
              aria-label="close"
              onClick={() => {
                formik.setValues((state) => {
                  return { ...state, days: botConfigs };
                });
              }}
              sx={{
                "&.MuiButtonBase-root:hover": {
                  bgcolor: "transparent",
                },
                p: 0,
              }}
            >
              <RestartAltIcon />
            </IconButton>
          )}

          <IconButton
            aria-label="close"
            onClick={() => handleClose(id)}
            sx={{
              ml: 1,
              "&.MuiButtonBase-root:hover": {
                bgcolor: "transparent",
              },
              p: 0,
            }}
          >
            <LazyLoadImage src={Close} />
          </IconButton>
        </div>
      </DialogTitle>
      <Divider />
      {data?.id !== "create-department" && data?.id !== "create-bot-config" && (
        <ProfileImage
          btnClassName={"uploadBtn"}
          className="add-operator-image"
          id="add-operator-drawer"
        />
      )}
      {data?.id === "create-bot-config" ? (
        <form onSubmit={formik.handleSubmit}>
          <div
            className="operator-inputs-wrapper"
            style={{ display: "flex", flexDirection: "column", gap: "8px" }}
          >
            {Constants.days.map((day) => {
              const dayIsFounded = formik.values.days.find(
                (el) => el.day.number === day.value
              );
              return (
                <div key={day.value}>
                  <div
                    style={{
                      display: "flex",
                      flexDirection: "column",
                      gap: "8px",
                    }}
                  >
                    <div
                      style={{
                        display: "flex",
                        alignItems: "center",
                        justifyContent: "space-between",
                      }}
                    >
                      <Typography variant="h5">
                        {
                          day[
                            i18n.language === "fr"
                              ? "frenchTitle"
                              : i18n.language === "ar"
                              ? "arabicTitle"
                              : "title"
                          ]
                        }
                      </Typography>
                      <Checkbox
                        sx={{
                          color: "#EBEBEB",
                          "&.Mui-checked": {
                            color: "#6932FA",
                          },
                          "& .MuiSvgIcon-root": {
                            borderRadius: "20%",
                          },
                        }}
                        inputProps={{ "aria-label": "controlled" }}
                        checked={!dayIsFounded ? false : true}
                        onChange={(e) =>
                          e.target.checked
                            ? formik.setValues((state) => {
                                return {
                                  ...state,
                                  days: [
                                    ...state.days,
                                    {
                                      day: {
                                        number: day.value,
                                        workInterval: [
                                          {
                                            startDate: null,
                                            endDate: null,
                                            _id: nanoid(),
                                          },
                                        ],
                                      },
                                    },
                                  ],
                                };
                              })
                            : formik.setValues((state) => {
                                return {
                                  ...state,
                                  days: state.days.filter(
                                    (item) => item?.day?.number !== day.value
                                  ),
                                };
                              })
                        }
                      />
                    </div>
                    {dayIsFounded &&
                      dayIsFounded.day.workInterval.map((item, index) => {
                        let startDate = new Date(Date.now());
                        typeof item.startDate === "string" &&
                          (() => {
                            startDate.setHours(item.startDate.split(":")[0]);
                            startDate.setMinutes(item.startDate.split(":")[1]);
                          })();

                        let endDate = new Date(Date.now());
                        typeof item.endDate === "string" &&
                          (() => {
                            endDate.setHours(item.endDate.split(":")[0]);
                            endDate.setMinutes(item.endDate.split(":")[1]);
                          })();

                        return (
                          <div
                            style={{
                              display: "flex",
                              justifyContent: "space-between",
                              alignItems: "center",
                            }}
                            key={item._id}
                          >
                            <div
                              style={{
                                display: "flex",
                                gap: "20px",
                                alignItems: "center",
                              }}
                            >
                              <LocalizationProvider dateAdapter={AdapterDayjs}>
                                <DemoContainer
                                  components={[
                                    "MultiInputTimeRangeField",
                                    "SingleInputTimeRangeField",
                                  ]}
                                >
                                  <MultiInputTimeRangeField
                                    ampm={false}
                                    slotProps={{
                                      textField: ({ position }) => ({
                                        label:
                                          position === "start"
                                            ? t("From")
                                            : t("To"),
                                      }),
                                    }}
                                    onChange={(e) => {
                                      handleChangeInterval(e, item?._id, day);
                                    }}
                                    defaultValue={[
                                      item?.startDate === null
                                        ? null
                                        : dayjs(startDate),
                                      item?.endDate === null
                                        ? null
                                        : dayjs(endDate),
                                    ]}
                                  />
                                </DemoContainer>
                              </LocalizationProvider>
                              <Button
                                onClick={() => {
                                  handleDeleteInterval(day, item?._id);
                                }}
                                disabled={
                                  dayIsFounded.day.workInterval.length === 1
                                }
                              >
                                <DeleteIcon sx={{ color: "#ED3863" }} />
                              </Button>
                            </div>

                            {index === 0 && (
                              <Button
                                disabled={
                                  dayIsFounded.day.workInterval.length > 1
                                }
                                sx={{
                                  backgroundColor: "#6932FA",
                                  borderRadius: "50%",
                                  minWidth: "35px",
                                  height: "35px",
                                  p: 0,
                                  m: 0,
                                  ":hover": {
                                    backgroundColor: "#6932FA",
                                  },
                                }}
                                onClick={() => {
                                  dayIsFounded.day.workInterval.length === 1 &&
                                    handleAddInterval(day);
                                }}
                              >
                                <AddIcon
                                  sx={{ color: "#fff", fontSize: "18px" }}
                                />
                              </Button>
                            )}
                          </div>
                        );
                      })}
                  </div>
                </div>
              );
            })}

            <Button
              sx={{
                backgroundColor: "#6932FA",
                boxShadow:
                  "rgba(0, 0, 0, 0.2) 0px 3px 5px -1px, rgba(0, 0, 0, 0.14) 0px 6px 10px 0px, rgba(0, 0, 0, 0.12) 0px 1px",
                ":hover": { backgroundColor: "#6932FA" },
                color: "#fff",
                mt: 3,
                fontWeight: "bold",
                borderRadius: "8px",
              }}
              type="submit"
            >
              {t("Submit")}
            </Button>
          </div>
        </form>
      ) : (
        <Accordion style={{ height: "100%" }}>
          <AccordionSummary
            expandIcon={<ExpandMoreIcon />}
            aria-controls="panel1a-content"
            id="panel1a-header"
          >
            {" "}
            <Typography>{t("Basic Information")}</Typography>
          </AccordionSummary>
          <FormProvider methods={methods} onSubmit={handleSubmit(onSubmit)}>
            {data?.id === "create-department" ? (
              <div className="operator-inputs-wrapper">
                <div className="fistname-lastname-email-wrapper">
                  <Input
                    label={t("Name")}
                    placeholder={t("Name")}
                    name="name"
                    errors={methods.formState.errors}
                    register={register}
                  />

                  <Input
                    label={t("Description")}
                    placeholder={t("Description")}
                    name="description"
                    errors={methods.formState.errors}
                    register={register}
                  />
                </div>
              </div>
            ) : (
              <Grid container spacing={3} className="operator-inputs-wrapper">
                {/* <div className="fistname-lastname-email-wrapper"> */}
                <Grid item xs={12} md={6}>
                  {" "}
                  <Input
                    label={t("First Name")}
                    placeholder={t("First Name")}
                    name="firstname"
                    errors={methods.formState.errors}
                    register={register}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {" "}
                  <Input
                    label={t("Email")}
                    placeholder={t("Email")}
                    name="email"
                    errors={methods.formState.errors}
                    register={register}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {" "}
                  <Input
                    label={t("Password")}
                    placeholder={t("Password")}
                    name="password"
                    errors={methods.formState.errors}
                    register={register}
                    //  className="password-input"
                  />
                </Grid>

                <Grid item xs={12} md={6}>
                  {" "}
                  <Input
                    label={t("Last Name")}
                    placeholder={t("Last Name")}
                    name="lastname"
                    errors={methods.formState.errors}
                    register={register}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {" "}
                  <label style={{ marginTop: "12px" }}>
                    {t("Phone Number")}
                  </label>
                  <Controller
                    control={methods.control}
                    name="phone"
                    render={({
                      field: { ref, ...field },
                      fieldState: { error },
                    }) => (
                      <>
                        <MuiPhoneNumber
                          style={{ marginTop: "16px" }}
                          variant="outlined"
                          defaultCountry="tn"
                          fullWidth
                          InputLabelProps={{ shrink: true }}
                          margin="dense"
                          name="phone"
                          value={
                            "+216" + methods.formState?.defaultValues?.phone
                          }
                          onChange={(e) => {
                            field.onChange(
                              e
                                ?.split(" ")
                                .slice(1, e?.split(" ").length)
                                .join("")
                            );
                          }}
                          InputProps={{
                            style: {
                              borderRadius: "8px",
                              backgroundColor: "white",
                              height: "45.5px",
                            },
                          }}
                          error={
                            Object?.keys(methods.formState.errors)?.includes(
                              "phone"
                            )
                              ? true
                              : false
                          }
                        />
                        {Object?.keys(methods.formState.errors)?.includes(
                          "phone"
                        ) && (
                          <span style={{ color: "red" }}>{error?.message}</span>
                        )}
                      </>
                    )}
                  />
                </Grid>
                <Grid item xs={12} md={6}>
                  {" "}
                  <SelectComponent
                    data={
                      departments &&
                      departments?.map((department) => {
                        return {
                          value: department?._id,
                          label: department?.name,
                        };
                      })
                    }
                    name="department"
                    label={t("Department")}
                    defaultValue={
                      data?.department?.name || t("Select Department")
                    }
                    errors={methods.formState.errors}
                    control={methods.control}
                  />
                </Grid>
                <Grid item xs={12} md={12}>
                  {" "}
                  <SelectComponent
                    data={
                      roles &&
                      roles?.map((role) => {
                        return {
                          value: role?._id,
                          label: role?.code,
                        };
                      })
                    }
                    name="role"
                    label={t("Role")}
                    defaultValue={data?.role?.code || t("Select Role")}
                    errors={methods.formState.errors}
                    control={methods.control}
                  />
                </Grid>
              </Grid>
            )}

            <Button
              id="add-operator-btn"
              type="submit"
              onMouseEnter={handleMouseOver}
              onMouseLeave={handleMouseOut}
            >
              {(data?.id === "create-department"
                ? statusCreateDepartment
                : statusCreateOperator) === "loading" ? (
                <CircularProgress
                  className={
                    isHovering
                      ? "circular-progress-add-operator circular-progress-add-operator-hovering"
                      : "circular-progress-add-operator"
                  }
                />
              ) : data?.id === "create-department" ? (
                t("Add Department")
              ) : (
                t("Add Operator")
              )}
            </Button>
          </FormProvider>
        </Accordion>
      )}
    </Drawer>
  );
};

export default AddOperator;
